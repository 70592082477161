import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { getThemeColor } from 'poly-book-admin';
import { bool, string } from 'prop-types';

import { InputError } from './Inputs.js';

const SelectComp = React.forwardRef((props, ref) => (
  <ReactSelect classNamePrefix="select" ref={ref} {...props} />
));

const SelectCompS = styled(SelectComp)`
  width: 100%;
  height: '40px';
  .select__control,
  .select__control:hover,
  .select__control:focus {
    border-radius: 3px;
    border: 1px solid;
    border-color: ${getThemeColor(['lightGrey'])};
    font-family: inherit;
    font-size: 14px;
    box-shadow: none;
    height: 40px;
  }

  .select__control--is-focused {
    border-radius: 3px;
    border: 2px solid;
    border-color: ${({ branding }) =>
      getThemeColor([branding, 'primaryColor'])};
    font-family: inherit;
    font-size: 14px;
  }

  .select__input {
    color: rgb(94, 98, 113);
  }

  .select__option--is-selected {
    background-color: ${({ branding }) =>
      getThemeColor([branding, 'primaryColor'])};
  }
`;

const InputErrorS = styled(InputError)`
  margin-top: 5px;
`;

export function Select({ hasError, error, ...props }) {
  return (
    <div>
      <SelectCompS {...props} />
      {hasError && error && <InputErrorS>{error}</InputErrorS>}
    </div>
  );
}

Select.propTypes = {
  hasError: bool,
  error: string,
};
