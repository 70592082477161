import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { history, useRouterParams } from 'poly-client-routing';
import { setTemporalUserToken } from 'poly-apollo-client';
import { graphqlTemporalTokenRoute } from 'poly-utils';
import { jsonPostRequest } from 'poly-client-utils';
import { getThemeColor } from 'poly-book-admin';

import { Text } from '../../components/Text.js';
import { FlexCenter, RequestForm } from './RequestForm.js';
import { PlatformLogo } from '../../components/PlatformLogo.js';
import { usePropertyByTokenData } from './usePropertyByTokenData.js';

const ImageLogo = styled.img`
  margin-bottom: 19px;
  max-height: 75px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 15px;
`;

const FormWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border-color: ${getThemeColor(['lightGrey'])};
  background-color: #fff;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #17130f;
  margin-bottom: 20px;
  text-align: center;
`;

const SubTitle = styled.p`
  opacity: 0.5;
  text-align: center;
  margin: 16px 0;
`;

const TextS = styled(Text)`
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
`;

const { GRAPHQL_HTTP_URL = '' } = process.env;

export function RequestFormPage() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const { randomId } = useRouterParams(['randomId']);

  const { propertyName, branding, logoImageUrl, clientName, loading } =
    usePropertyByTokenData(isAuthorized);

  useEffect(() => {
    const authUser = async () => {
      try {
        const { token } = await jsonPostRequest(
          graphqlTemporalTokenRoute(GRAPHQL_HTTP_URL),
          {
            randomId,
          },
        );
        setTemporalUserToken(token);
        setIsAuthorized(true);
      } catch {
        history.push('/');
      }
    };
    authUser();
  }, []);

  if (!isAuthorized) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <FlexCenter>
        {logoImageUrl ? (
          <ImageLogo src={logoImageUrl} alt="Client Logo" />
        ) : (
          <TextS>{clientName}</TextS>
        )}
      </FlexCenter>
      <FormWrapper>
        <Title>Facilities Repair Request</Title>
        <SubTitle>{propertyName}</SubTitle>
        <RequestForm branding={branding} />
      </FormWrapper>
      <FlexCenter>
        <PlatformLogo branding={branding} />
      </FlexCenter>
    </Wrapper>
  );
}
