import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { getThemeColor, getThemeFont, Loader } from 'poly-book-admin';
import { ClientBranding } from 'poly-constants';

const buttonStyles = css`
  color: #fff;
  cursor: pointer;
  border-radius: 0;
  border: 1px solid;
  position: relative;

  &:disabled {
    opacity: 0.5;
  }

  & > svg > circle {
    stroke: #fff;
  }
`;

const PolyButton = styled.button`
  ${buttonStyles}
  padding: 9px 20px;
  text-align: center;
  border-color: ${getThemeColor([ClientBranding.POLY, 'secondaryColorLight'])};
  background-color: ${getThemeColor([ClientBranding.POLY, 'secondaryColor'])};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  transition: all 0.3s ease 0s;
  &:hover {
    background: ${getThemeColor([ClientBranding.POLY, 'secondaryColorLight'])};
  }
`;

const EsfmxButton = styled.button`
  ${buttonStyles}
  background: ${getThemeColor([ClientBranding.ESFMX, 'primaryColor'])};
  border-color: ${getThemeColor([ClientBranding.ESFMX, 'primaryColor'])};
  box-sizing: border-box;
  font-family: ${getThemeFont(['defaultFont'])};
  font-size: 16px;
  font-weight: bold;
  min-width: 190px;
  padding: 0 20px;
  height: 45px;
  transition: background-color 0.2s linear, border-color 0.2s linear,
    color 0.2s linear;
  transition: 0.3s;
  &:hover {
    background-color: ${getThemeColor([
      ClientBranding.ESFMX,
      'secondaryColor',
    ])};
    border-color: ${getThemeColor([ClientBranding.ESFMX, 'secondaryColor'])};
    transition: 0.3s;
  }
  &:hover:before {
    top: 0;
    left: 0;
    transition: 0.3s;
  }
  &:before {
    height: 45px;
    min-width: 190px;
    content: '';
    top: 5px;
    left: 5px;
    position: absolute;
    border: 1px solid;
    border-color: ${getThemeColor([ClientBranding.ESFMX, 'secondaryColor'])};
    transition: 0.3s;
    box-sizing: border-box;
  }
`;

const buttonComponents = {
  [ClientBranding.POLY]: PolyButton,
  [ClientBranding.ESFMX]: EsfmxButton,
};

export function Button({ branding, children, loading, ...props }) {
  const Component = buttonComponents[branding];

  return (
    <Component disabled={loading} {...props}>
      {children} {loading && <Loader size={25} />}
    </Component>
  );
}

Button.propTypes = {
  branding: string.isRequired,
  children: string.isRequired,
  loading: bool,
};

export const PhoneLinkButton = styled.a`
  display: inline-block;
  font-size: ${R.propOr('18px', 'fontSize')};
  font-weight: 500;
  color: ${({ branding }) => getThemeColor([branding, 'primaryColor'])};
  text-decoration: none;
`;
