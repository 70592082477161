import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

const propertyByTokenQuery = gql`
  query PROPERTY_BY_TOKEN_QUERY {
    requestsPropertyByToken {
      name
      client {
        name
        configs {
          logoImage {
            url
          }
          requestsApp {
            branding
          }
        }
      }
    }
  }
`;

// getPropertyInfoByQueryData :: QueryData -> PropertyInfo
// PropertyInfo = {
//   propertyName: String,
//   clientName: String,
//   branding: String,
//   logoImageUrl: String
// }
const getPropertyInfoByQueryData = R.compose(
  R.applySpec({
    propertyName: R.prop('name'),
    clientName: R.path(['client', 'name']),
    branding: R.path(['client', 'configs', 'requestsApp', 'branding']),
    logoImageUrl: R.path(['client', 'configs', 'logoImage', 'url']),
  }),

  R.prop('requestsPropertyByToken'),
);

export const usePropertyByTokenData = (isAuthorized) => {
  const { data, loading } = useQuery(propertyByTokenQuery, {
    skip: !isAuthorized,
  });

  return {
    ...getPropertyInfoByQueryData(data),
    loading,
  };
};
