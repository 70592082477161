import React, { useState } from 'react';

import styled from 'styled-components';
import {
  OldVersionDetectorMessage,
  useOldVersionDetector,
} from 'poly-client-utils/src/useOldVersionDetector.js';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgb(252, 252, 252);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  max-width: 300px;
  padding: 10px 30px 10px 30px;
  background-color: #ffeec4;
  border: 1px solid transparent;
  border-radius: 2px;
`;

const ReloadButton = styled.span`
  color: #436cbe;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
`;

export function OldVersionDetector() {
  const [newVersion, setNewVersion] = useState('');
  const notifyUserAboutNewVersion = (version) => {
    setNewVersion(version);
  };

  const reloadPage = useOldVersionDetector(notifyUserAboutNewVersion);

  if (newVersion) {
    return (
      <FlexContainer>
        <Wrapper>
          <OldVersionDetectorMessage version={newVersion} />
          <ReloadButton onClick={reloadPage}>Reload</ReloadButton>
        </Wrapper>
      </FlexContainer>
    );
  }

  return null;
}
