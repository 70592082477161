import { string } from 'prop-types';
import { useField } from 'react-final-form';
import { useEffect, useRef, useCallback } from 'react';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from 'poly-client-utils';
import { debounce } from 'poly-utils';

import { postLSRequestFormValues } from '../utils.js';

const usePrevious = (val) => {
  const ref = useRef(val);

  useEffect(() => {
    ref.current = val;
  }, [val]);

  return ref.current;
};

export function OnChangeSetToLSFormValue({ name }) {
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } });
  const prevValue = usePrevious(value);

  const onChangeDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)((values) => {
      postLSRequestFormValues(values);
    }),
    [],
  );

  useEffect(() => {
    if (value !== prevValue) {
      onChangeDebounced({ [name]: value });
    }
  }, [value, prevValue]);

  return null;
}

OnChangeSetToLSFormValue.propTypes = { name: string.isRequired };
